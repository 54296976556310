import clsx from "clsx"
import { ENDivider } from "en-react/dist/src/components/Divider"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { Controller, useFormContext } from "react-hook-form"
import { EnSimpleInput, ZtnaSelect } from "src/shared/components/FormComponents"
import DialogActions from "src/shared/components/Modal/DialogActions"
import ZtnaButton from "src/shared/components/ZtnaButton/ZtnaButton"
import { useIpsecConfigurationsStyles } from "../IpsecConfigurations.styles"

interface IkePolicyProps {
  setActiveStep: (val: number) => void
  onCloseModal: () => void
}

const IkePolicy = ({ setActiveStep, onCloseModal }: IkePolicyProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const classes = useIpsecConfigurationsStyles()

  const ikeVersionOptions = [{ label: "V2", value: "V2" }]
  const authMethodOptions = [{ label: "Pre-Shared Keys (PSK)", value: "Pre-Shared Keys (PSK)" }]
  const ikeEncryptionAlgoOptions = [{ label: "aes256-sha256-modp2048", value: "aes256-sha256-modp2048" }]
  const ikeAuthAlgoOptions = [{ label: "aes256-sha256-modp2048", value: "aes256-sha256-modp2048" }]
  const ikeDhGroupOptions = [{ label: "14", value: "14" }]

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.selectContainer}>
          <ENTextPassage>
            <span className={classes.labelClass}>IKE Version</span>
          </ENTextPassage>
          <div className={clsx(classes.select, { [classes.disabledSelect]: true })}>
            <Controller
              control={control}
              name="ikeVersion"
              render={({ field: { onChange, value } }) => (
                <ZtnaSelect value={value} options={ikeVersionOptions} onChange={onChange} disabled />
              )}
            />
          </div>
        </div>

        <div className={classes.selectContainer}>
          <ENTextPassage>
            <span className={classes.labelClass}>Authentication Method</span>
          </ENTextPassage>
          <div className={clsx(classes.select, { [classes.disabledSelect]: true })}>
            <Controller
              control={control}
              name="authMethod"
              render={({ field: { onChange, value } }) => (
                <ZtnaSelect value={value} options={authMethodOptions} onChange={onChange} disabled />
              )}
            />
          </div>
        </div>

        <div className={classes.selectContainer}>
          <ENTextPassage>
            <span className={classes.labelClass}>Encryption Algorithm</span>
          </ENTextPassage>
          <div className={clsx(classes.select, { [classes.disabledSelect]: true })}>
            <Controller
              control={control}
              name="ikeEncryptionAlgo"
              render={({ field: { onChange, value } }) => (
                <ZtnaSelect value={value} options={ikeEncryptionAlgoOptions} onChange={onChange} disabled />
              )}
            />
          </div>
        </div>

        <div className={classes.selectContainer}>
          <ENTextPassage>
            <span className={classes.labelClass}>Authentication Algorithm</span>
          </ENTextPassage>
          <div className={clsx(classes.select, { [classes.disabledSelect]: true })}>
            <Controller
              control={control}
              name="ikeAuthAlgo"
              render={({ field: { onChange, value } }) => (
                <ZtnaSelect value={value} options={ikeAuthAlgoOptions} onChange={onChange} disabled />
              )}
            />
          </div>
        </div>

        <div className={classes.selectContainer}>
          <ENTextPassage>
            <span className={classes.labelClass}>DH Group</span>
          </ENTextPassage>
          <div className={clsx(classes.select, { [classes.disabledSelect]: true })}>
            <Controller
              control={control}
              name="ikeDhGroup"
              render={({ field: { onChange, value } }) => (
                <ZtnaSelect value={value} options={ikeDhGroupOptions} onChange={onChange} disabled />
              )}
            />
          </div>
        </div>

        <div className={classes.inputField}>
          <EnSimpleInput
            name="ikeLifeTime"
            control={control}
            label="LifeTime"
            labelClass={classes.labelClass}
            placeholder="Enter lifeTime"
            error={errors.ikeLifeTime}
            units="Seconds"
            disable
          />
        </div>

        <div className={classes.inputField}>
          <EnSimpleInput
            name="ikeAllowedBandwidth"
            control={control}
            label="Allowed Bandwidth"
            labelClass={classes.labelClass}
            placeholder="Enter allowed bandwidth"
            error={errors.ikeAllowedBandwidth}
            units="KBs"
            disable
          />
        </div>
      </div>

      <ENDivider />

      <DialogActions className={classes.stepFooter}>
        <ZtnaButton buttonType="secondary" title="Cancel" onClick={onCloseModal} />
        <ZtnaButton buttonType="primary" title="Next" onClick={() => setActiveStep(1)} />
      </DialogActions>
    </>
  )
}

export default IkePolicy
