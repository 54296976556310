import { createUseStyles } from "react-jss"

export const useIpsecConfigurationsStyles = createUseStyles((theme) => ({
  wrapper: {
    paddingTop: 16,
    paddingLeft: 24,
    paddingBottom: 38,
    display: "flex",
    flexWrap: "wrap",
    rowGap: 21,
    columnGap: 34,
  },
  selectContainer: {
    width: 288,
    "& div": { borderRadius: 4 },
  },
  select: {
    marginTop: 2,
    fontSize: 14,
    "& .react-select__control": {
      height: "39px !important",
    },
    "& .react-select__single-value": {
      color: theme.color.text.primary,
    },
  },
  disabledSelect: {
    "& .react-select__control": {
      border: 0,
      backgroundColor: theme.color.grey[400],
    },
    "& .react-select__single-value": {
      color: theme.color.secondary[600],
    },
  },
  inputField: {
    width: 288,
    "& .MuiOutlinedInput-root": {
      height: 39,
      borderRadius: 4,
    },
    "& input": {
      color: theme.color.text.primary,
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "& .Mui-disabled": {
      color: theme.color.secondary[600],
    },
  },
  labelClass: {
    fontSize: 14,
    marginBottom: "2px !important",
    color: theme.color.grey[300],
    "& b": {
      fontWeight: "400 !important",
    },
  },
  stepFooter: { height: 70 },
}))
