import { createUseStyles } from "react-jss"

export const usePolicyEvaluationResultStyles = createUseStyles((theme) => ({
  header: {
    display: "flex",
    gap: 20,
    marginBottom: 16,
  },
  headerHeading: {
    fontSize: 24,
    fontWeight: 600,
    paddingTop: 2,
  },
  cursorPointer: {
    cursor: "pointer",
  },
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.color.border.divider}`,
    backgroundColor: theme.color.background.surfaceElevation1,
    padding: "16px 16px 24px 16px",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
  },
  cardHeading: {
    fontSize: 16,
  },
  legendsContainer: {
    display: "flex",
    gap: 24,
  },
  legendContainer: {
    display: "flex",
    alignItems: "center",
    gap: 6,
  },
  legend: {
    height: 8,
    width: 8,
    borderRadius: "50%",
  },
  greenLegend: {
    backgroundColor: theme.color.background.successDefault,
  },
  redLegend: {
    backgroundColor: theme.color.background.dangerDefault,
  },
  legendText: {
    fontSize: 14,
  },
  loader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  loaderText: {
    marginTop: 6,
    fontSize: 13,
    color: theme.color.content.secondary,
  },
  policiesContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    paddingLeft: 24,
  },
  policyItem: {
    display: "flex",
    alignItems: "center",
    gap: 16,
  },
  policyText: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    height: 20,
  },
  showButton: {
    fontWeight: 600,
    color: theme.color.content.accentDefault,
    marginTop: 8,
    marginLeft: 16,
    width: "fit-content",
  },
  blankslate: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 19,
    padding: "15px 0px 10px",
    "& p": {
      textAlign: "center",
    },
  },
  blankslateIcon: {
    padding: 14,
    borderRadius: "50%",
    backgroundColor: theme.color.background.surfaceElevation2,
  },
  errorSlate: {
    padding: "0 24px",
  },
}))
