import clsx from "clsx"
import { ENButton } from "en-react/dist/src/components/Button"
import { ENHeading } from "en-react/dist/src/components/Heading"
import { ENIconRefresh } from "en-react/dist/src/components/Icons/Refresh"
import { ENIconSearch } from "en-react/dist/src/components/Icons/Search"
import { ENTextField } from "en-react/dist/src/components/TextField"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { Fragment, useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import { useDispatch, useSelector } from "react-redux"
import Can from "src/casl/ability"
import { PopoverButtonType } from "src/components/DataGrid/DataGrid"
import ZtnaIcon, { IconNameType } from "src/shared/components/Icons"
import ZtnaButton from "src/shared/components/ZtnaButton"
import { RootState } from "src/store"
import { hidePageHeader, showPageHeader } from "src/store/ui/uiSlice"
import theme from "src/theme"
import CircularLoader from "../CicularLoader/CircularLoader"
import ZtnaMenu from "../ZtnaMenu"
import ZtnaTooltip from "../ZtnaTooltip"
import { usePageHeaderStyles } from "./PageHeader.styles"

export interface PageHeaderButtonsType {
  id?: string
  hideText?: boolean
  name: string
  title: string
  styles?: React.CSSProperties
  callback: (name: string) => void
  isDisabled?: boolean
  isLoading?: boolean
  hide?: boolean
  className?: string
  type?: "dropdown" | "refresh"
  buttonType: "primary" | "secondary" | "tertiary" | "error"
  buttonContentColor?: string
  popOverList?: PopoverButtonType[]
  buttonTooltip?: string
  tooltipPlacement?: "top" | "bottom" | "left" | "right"
  isStartIcon?: boolean
  buttonIconName?: IconNameType
  refreshIntervalText?: string
  onRefreshCallBack?: () => void
}

export type PageHeaderType = {
  title?: string
  hideText?: boolean
  description?: string
  buttons?: PageHeaderButtonsType[]
  marginTop?: number
  marginBottom?: number
  searchCallBack?: (query: string) => void
  searchPlaceholder?: string
  disableLiveSearch?: boolean // Removed
  searchBarId?: string
  descriptionClass?: string
  noPortal?: boolean
  onRefreshCallBack?: () => void
  onClearSearchCallBack?: () => void
  refreshIntervalText?: string
  searchValue?: string
  showButtonsSeparator?: boolean
}

const PageHeader: React.FC<React.PropsWithChildren<PageHeaderType>> = (props) => {
  const classes = usePageHeaderStyles()
  const {
    sideNav: { isOpened },
  } = useSelector((state: RootState) => state.ui)
  const {
    title,
    description,
    buttons,
    searchCallBack,
    onClearSearchCallBack,
    searchPlaceholder = "Search",
    children,
    noPortal,
    onRefreshCallBack,
    refreshIntervalText,
    searchValue,
    showButtonsSeparator = true,
  } = props

  const containerRef = useRef(document.getElementById("app-container-page-header-container") as HTMLElement)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(showPageHeader())
    containerRef.current = document.getElementById("app-container-page-header-container") as HTMLElement

    return () => {
      dispatch(hidePageHeader())
    }
  }, [])

  if (!containerRef.current) {
    return null
  }

  const render = (
    <div
      className={clsx(
        classes.root,
        { [classes.navMargin]: description && !isOpened },
        { [classes.navMarginSidebarOpen]: description && isOpened },
        { [classes.oneColumn]: !description },
      )}
    >
      {title && <ENHeading variant="lg">{title}</ENHeading>}
      {description && (
        <ENTextPassage>
          <p>{description}</p>
        </ENTextPassage>
      )}
      <div className={classes.actionsContainer}>
        {searchCallBack && (
          <div className={classes.searchContainer}>
            <ENTextField
              label="Search"
              enableSlotAfterClick
              placeholder={searchPlaceholder}
              handleOnChange={(e: any) => searchCallBack(e?.target?.value)}
              value={searchValue}
            >
              <ENIconSearch slot="before" />
              <Fragment key="0.001">
                <ENButton
                  slot="after"
                  variant="tertiary"
                  className={classes.searchPostIconStyles}
                  onClick={onClearSearchCallBack}
                >
                  <ZtnaIcon name="enClose" color={theme.color.content.default} />
                </ENButton>
              </Fragment>
            </ENTextField>
          </div>
        )}
        {searchCallBack &&
          showButtonsSeparator &&
          buttons?.length &&
          buttons.find((button: PageHeaderButtonsType) => !button?.hide) && <div className={classes.divider} />}

        <Can I="view" a="pageHeaderButton">
          {buttons?.length && (
            <div className={classes.buttonsContainer}>
              {buttons.map(
                (
                  {
                    id,
                    name,
                    title,
                    hideText,
                    callback,
                    styles,
                    isDisabled,
                    isLoading,
                    hide: hideButton,
                    className,
                    type,
                    popOverList,
                    buttonType,
                    buttonContentColor,
                    buttonTooltip,
                    buttonIconName,
                    isStartIcon = false,
                    refreshIntervalText,
                  },
                  index,
                ) =>
                  !hideButton &&
                  (type === "dropdown" ? (
                    <Fragment key={id}>
                      <ZtnaMenu
                        title={title}
                        buttonProps={{
                          variant: buttonType,
                          hideText: hideText,
                          dataTestid: id,
                          id: "en-ztna-pageHeader-dropdown",
                        }}
                        items={popOverList || []}
                      />
                    </Fragment>
                  ) : type === "refresh" ? (
                    <Fragment key={id}>
                      {buttonTooltip ? (
                        <ZtnaTooltip
                          title={buttonTooltip}
                          arrow
                          classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                          placement="bottom"
                        >
                          <ZtnaButton
                            dataTestid={id}
                            buttonType={buttonType}
                            id={id}
                            hideText={hideText}
                            name={name}
                            onClick={() => callback(name)}
                            className={classes.button}
                            contentColor={buttonContentColor}
                            style={styles}
                            disabled={isDisabled}
                            iconClass={className}
                            title={
                              isLoading ? (
                                <CircularLoader isDark={buttonType === "primary"} />
                              ) : (
                                <ZtnaIcon
                                  name={"enRefresh"}
                                  size="lg"
                                  color={buttonContentColor || theme.color.background.transparentDefault}
                                />
                              )
                            }
                          />
                        </ZtnaTooltip>
                      ) : (
                        <div className={clsx({ [classes.refreshButtonContainer]: refreshIntervalText })}>
                          <ZtnaButton
                            dataTestid={id}
                            buttonType={buttonType}
                            id={id}
                            hideText={hideText}
                            name={name}
                            onClick={() => callback(name)}
                            className={clsx(classes.button, className)}
                            contentColor={buttonContentColor}
                            style={styles}
                            disabled={isDisabled}
                            title={
                              <div className={clsx({ [classes.refreshButton]: refreshIntervalText })}>{title}</div>
                            }
                            startIcon={
                              isLoading ? (
                                <CircularLoader isDark={buttonType === "primary"} />
                              ) : buttonIconName && isStartIcon ? (
                                <ZtnaIcon
                                  name={buttonIconName}
                                  color={buttonContentColor || theme.color.background.transparentDefault}
                                />
                              ) : undefined
                            }
                            endIcon={
                              buttonIconName && !isStartIcon ? (
                                <ZtnaIcon
                                  name={buttonIconName}
                                  color={buttonContentColor || theme.color.background.transparentDefault}
                                />
                              ) : undefined
                            }
                          />
                          {refreshIntervalText && (
                            <div className={classes.refreshIntervalText}>{refreshIntervalText}</div>
                          )}
                        </div>
                      )}
                      {showButtonsSeparator && buttons?.length && buttons?.length - 1 !== index && (
                        <div className={classes.divider} />
                      )}
                    </Fragment>
                  ) : (
                    <Fragment key={id}>
                      {buttonTooltip ? (
                        <ZtnaTooltip
                          title={buttonTooltip}
                          arrow
                          classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                        >
                          <div>
                            <ZtnaButton
                              dataTestid={id}
                              buttonType={buttonType}
                              id={id}
                              hideText={hideText}
                              name={name}
                              onClick={() => callback(name)}
                              className={clsx(classes.button, className)}
                              contentColor={buttonContentColor}
                              style={styles}
                              disabled={isDisabled}
                              startIcon={isLoading && <CircularLoader isDark={buttonType === "primary"} />}
                              title={title}
                            />
                          </div>
                        </ZtnaTooltip>
                      ) : (
                        <ZtnaButton
                          dataTestid={id}
                          buttonType={buttonType}
                          id={id}
                          hideText={hideText}
                          name={name}
                          onClick={() => callback(name)}
                          className={clsx(classes.button, className)}
                          contentColor={buttonContentColor}
                          style={styles}
                          disabled={isDisabled}
                          title={title}
                          startIcon={
                            isLoading ? (
                              <CircularLoader isDark={buttonType === "primary"} />
                            ) : buttonIconName && isStartIcon ? (
                              <ZtnaIcon
                                name={buttonIconName}
                                color={buttonContentColor || theme.color.background.transparentDefault}
                              />
                            ) : undefined
                          }
                          endIcon={
                            buttonIconName && !isStartIcon ? (
                              <ZtnaIcon
                                name={buttonIconName}
                                color={buttonContentColor || theme.color.background.transparentDefault}
                              />
                            ) : undefined
                          }
                        />
                      )}
                      {showButtonsSeparator && buttons?.length && buttons?.length - 1 !== index && (
                        <div className={classes.divider} />
                      )}
                    </Fragment>
                  )),
              )}
            </div>
          )}
        </Can>

        {onRefreshCallBack && (
          <ZtnaTooltip title={refreshIntervalText} placement="left" arrow>
            <div role="presentation" onClick={onRefreshCallBack} className={classes.refresh}>
              <ENIconRefresh slot="end" size="lg" />
            </div>
          </ZtnaTooltip>
        )}
        <>{children}</>
      </div>
    </div>
  )

  if (noPortal) {
    return render
  } else {
    return createPortal(render, containerRef.current)
  }
}

export default PageHeader
