import { ENSkeleton } from "en-react/dist/src/components/Skeleton"
import { useDatGridLoadingComponentStyles } from "./DatGridLoadingComponent.styles"

const DataGridLoadingComponent: React.FC = (props: any) => {
  const classes = useDatGridLoadingComponentStyles()
  const colDefs = props?.api?.getColumnDefs()
  return (
    <div className={classes.root}>
      {colDefs?.map((item: any) => (
        <ENSkeleton width={item?.width || 50} height={24} />
      ))}
    </div>
  )
}

export default DataGridLoadingComponent
