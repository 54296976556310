export const DEFAULT_ERROR_MESSAGE =
  "An unexpected error occurred on the server side. Please try again. If the error persists contact the support team!"

export const SUCCESS_MESSAGES = {
  ADD_AUTOMATED_USER_PROFILE: "Automated User Profile has been added successfully",
  ADD_NETWORK_POLICY: "Network Policy has been added successfully",
  ADD_NETWORK_POLICY_RULE: "Rule has been added successfully",
  INTEGRATE_XIQ_ACCOUNT: "ExtremeCloud IQ has been integrated successfully",
  SYNC_XIQ_ACCOUNT: "Your ExtremeCloud IQ account has been synced successfully.",
  REMOVE_POLICY: "Network Policy has been removed successfully",
  REMOVE_USER_PROFILE: "User Profile has been removed successfully",
  REMOVE_POLICY_RULE: "Rule has been removed successfully",
  DEPLOY_RADSEC_PROXY:
    "RadSec Proxy deployed successfully for you. Please try again or contact your administrator for further assistance",
  DISABLE_RADSEC_PROXY: "Proxy has been disabled successfully",
  ENABLE_RADSEC_PROXY: "Proxy has been enabled successfully",
  RENEW_RADSEC_PROXY: "Proxy Certificate has been renewed successfully",
  REMOVE_RADSEC_PROXIES: "Proxy has been removed successfully",
  UPDATE_RADSEC_PROXY: "Proxy has been updated successfully",
  ADD_NETWORK_SITE: "Network Site has been added successfully",
  REMOVE_NETWORK_SITE: "Network Site has been removed successfully",
  UPDATE_NETWORK_SITE: "Network Site has been updated successfully",
  REMOVE_DEVICE: "Device has been removed successfully",
  UPDATE_NETWORK_DEVICE: "Network device has been updated successfully",
  ADD_DEVICES_SINGLE: "Device has been added successfully",
  ADD_DEVICES_MULTIPLE: "Devices added successfully",
  IMPORT_DEVICES: "Devices imported successfully",
  IMPORT_SINGLE_DEVICE: "Device imported successfully",
  MS_INTUNE_INTEGRATION: "Microsoft Intune Successfully Added",
  MS_INTUNE_INTEGRATION_UPDATE: "Microsoft Intune Successfully Updated",
}

export const ERROR_MESSAGES = {
  ADD_AUTOMATED_USER_PROFILE:
    "We're unable to add a profile for you. Please try again or contact your administrator for further assistance",
  ADD_NETWORK_POLICY:
    "We're unable to add a network policy for you. Please try again or contact your administrator for further assistance",
  ADD_NETWORK_POLICY_RULE:
    "We're unable to add a network policy rule for you. Please try again or contact your administrator for further assistance",
  INTEGRATE_XIQ_ACCOUNT:
    "We are unable to integrate with the ExtremeCloud IQ. Please contact IQ admin to make sure that you have the administrator access to ExtremeCloud IQ account.",
  SYNC_XIQ_ACCOUNT: "Your ExtremeCloud IQ account couldn't synced successfully due to an error. Try Again.",
  UNABLE_TO_FETCH: "We are unable to fetch the data. Please try again later",
  REMOVE_POLICY:
    "We are unable to remove a network policy for you. Please try again or contact your administrator for further assistance",
  REMOVE_USER_PROFILE:
    "We are unable to remove a user profile for you. Please try again or contact your administrator for further assistance",
  REMOVE_POLICY_RULE: "We're unable to remove the rule. Please try again or contact support for further assistance",
  INVALID_NAME_INPUT: "Name must have only alphanumeric characters with interim space and hyphen",
  DEPLOY_RADSEC_PROXY:
    "We're unable to deploy RadSec Proxy for you. Please try again or contact your administrator for further assistance",
  DEPLOY_RADSEC_COMMANDS:
    "We're unable to fetch RadSec Proxy installation command for you. Please try again or contact your administrator for further assistance",
  ADD_NETWORK_SITE:
    "We're unable to add network site for you. Please try again or contact your administrator for further assistance",
  DISABLE_RADSEC_PROXY:
    "We are unable to disable RadSec Proxy for you. Please try again or contact your administrator for further assistance",
  ENABLE_RADSEC_PROXY:
    "We are unable to enable RadSec Proxy for you. Please try again or contact your administrator for further assistance",
  RENEW_RADSEC_PROXY:
    "We are unable to renew RadSec Proxy certificate for you. Please try again or contact your administrator for further assistance",
  REMOVE_RADSEC_PROXIES:
    "We are unable to remove RadSec Proxy for you. Please try again or contact your administrator for further assistance",
  UPDATE_RADSEC_PROXY:
    "We are unable to update RadSec Proxy for you. Please try again or contact your administrator for further assistance",
  RENEW_RADSEC_CERTIFICATE:
    "We are unable to create RadSec Proxy certificate renewal command for you. Please try again or contact your administrator for further assistance",
  REMOVE_NETWORK_SITE:
    "We are unable to remove Network Site for you. Please try again or contact your administrator for further assistance",
  UPDATE_NETWORK_SITE:
    "We are unable to update Network Site for you. Please try again or contact your administrator for further assistance",
  COMMAND_EXPIRED: "This command has been expired.",
  REMOVE_DEVICE:
    "We are unable to remove Device for you. Please try again or contact your administrator for further assistance",
  UPDATE_NETWORK_DEVICE:
    "We are unable to update Network Device for you. Please try again or contact your administrator for further assistance",
  ADD_DEVICES:
    "We are unable to add Device for you. Please try again or contact your administrator for further assistance",
  IMPORT_DEVICES:
    "We are unable to import Devices for you. Please try again or contact your administrator for further assistance",
  GENERATE_CERTS:
    "We are unable to generate Certificate Bundle for you. Please try again or contact your administrator for further assistance",
  DOWNLOAD_CA_CERT:
    "We are unable to download CA Certificate for you. Please try again or contact your administrator for further assistance",
  UPDATE_MATCHING_CRITERIA_FOR_CLIENT:
    "We are unable to update matching criteria for clients. Please try again or contact your administrator for further assistance",
  UPDATE_OSCP_RESPONDER:
    "We are unable to update OCSP Responder. Please try again or contact your administrator for further assistance",
  INVALIDATE_CERTIFICATE:
    "We are unable to invalidate certificate. Please try again or contact your administrator for further assistance",
  RESET_CERTIFICATE:
    "We are unable to reset certificate. Please try again or contact your administrator for further assistance",
  SYNC_DEVICE: "We are unable to sync device. Please try again or contact your administrator for further assistance",
  UPDATE_CERTIFICATE:
    "We are unable to update certificate. Please try again or contact your administrator for further assistance",
  ADD_MAC_OUI:
    "We are unable to add MAC OUI for you. Please try again or contact your administrator for further assistance",
}
