import { TOP_NAV_HEIGHT } from "src/utils/constants"

type BreakPoints = "xs" | "sm" | "md" | "lg" | "xl"

type ColorNameType = "red" | "orange" | "amber" | "yellow" | "green" | "forest" | "cyan" | "blue" | "purple" | "magenta"
type ColorVariantType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15

type NeutralColorNameType = "neutralDark" | "neutralLight"
type NeutralColorVariantType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16

export type ThemeType = {
  spacing: (vertical: number, horizontal?: number) => string
  zIndex: {
    appBar: string
    pageHeader: string
    upgradeBanner: string
    modal: string
    toastNotification: string
    sideNav: string
    onBoardingButtonsContainer: string
    popup: string
  }
  transitions: {
    duration: {
      shortest: 150
      shorter: 200
      short: 250
      // most basic recommended timing
      standard: 300
      // this is to be used in complex animations
      complex: 375
      // recommended when something is entering screen
      enteringScreen: 225
      // recommended when something is leaving screen
      leavingScreen: 195
    }
    easing: {
      // This is the most common easing curve.
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)"
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)"
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: "cubic-bezier(0.4, 0, 1, 1)"
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)"
    }
  }
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: number
        }
        "*::-webkit-scrollbar-track": {
          "-webkit-box-shadow": string
          boxShadow: string
          borderRadius: number
          backgroundColor: string
        }
        "*::-webkit-scrollbar-thumb": {
          "-webkit-border-radius": number
          borderRadius: number
          backgroundColor: string
        }
      }
    }
  }
  mixins: {
    toolbar: {
      minHeight: number
      maxHeight: number
      height: number
    }
  }
  props: {
    MuiTypography: {
      variantMapping: {
        h1: string
        h2: string
        body1: string
        body2: string
        subtitle1: string
        subtitle2: string
      }
    }
  }
  breakpoints: {
    values: Record<BreakPoints, number>
    down: (key: BreakPoints | number) => string
    up: (key: BreakPoints | number) => string
    between: (start: BreakPoints | number, end: BreakPoints | number) => string
  }
  color: {
    // Extreme Cloud One Theme Colors
    background: {
      surfaceElevation0: string
      surfaceElevation1: string
      surfaceElevation2: string
      surfaceElevation3: string
      accentDefault: string
      accentEmphasis: string
      successDefault: string
      warningDefault: string
      dangerDefault: string
      dangerEmphasis: string
      infoDefault: string
      inverseDefault: string
      inverseEmphasis: string
      transparentDefault: string
      transparentEmphasis: string
      default?: string
      paper?: string
      failureDefault?: string
      greyDefault?: string
    }

    content: {
      default: string
      secondary: string
      tertiary: string
      inverseDefault: string
      accentDefault: string
      accentEmphasis: string
      successDefault: string
      successOnbg: string
      warningDefault: string
      warningOnbg: string
      dangerDefault: string
      dangerOnbg: string
      infoDefault: string
      infoOnbg: string
    }

    border: {
      divider: string
      default: string
      emphasis: string
      accentDefault: string
      accentEmphasis: string
      dangerDefault: string
    }

    // Old Theme Colors
    text: {
      primary: string
    }
    tertiary: {
      700: string
      600: string
      500: string
      400: string
      300: string
    }
    grey: {
      A100: string
      A200: string
      A300: string
      900: string
      800: string
      700: string
      600: string
      500: string
      400: string
      300: string
    }
    secondary: {
      main: string
      900: string
      800: string
      700: string
      600: string
      500: string
      400: string
      300: string
    }
    primary: {
      main: string
      900: string
      800: string
      700: string
      600: string
      500: string
      400: string
    }
    success: {
      main: string
      900: string
      800: string
      700: string
      600: string
      500: string
      400: string
    }
    warning: {
      main: string
      900: string
      800: string
      700: string
      600: string
      500: string
    }
    info: {
      main: string
      900: string
      800: string
      700: string
      600: string
    }
    error: {
      main: string
      light: string
      dark: string
      900: string
      800: string
      700: string
      600: string
    }
  } & Record<ColorNameType, Record<ColorVariantType, string>> &
    Record<NeutralColorNameType, Record<NeutralColorVariantType, string>>
  typography: {
    fontFamily: string
    h1: {
      fontSize: number
      color: string
      fontFamily: string
    }
    h2: {
      fontSize: number
      color: string
      fontFamily: string
    }
    h3: {
      fontSize: number
      color: string
      fontFamily: string
    }
    h4: {
      fontSize: number
      color: string
      fontFamily: string
    }
    subtitle1: {
      fontSize: number
      color: string
      fontFamily: string
    }
    subtitle2: {
      fontSize: number
      color: string
      fontFamily: string
    }
    body1: {
      fontSize: number
      color: string
      fontFamily: string
    }
    body2: {
      fontSize: number
      color: string
      fontFamily: string
    }
  }
  shape: {
    borderRadius: {
      xs: number
      sm: number
      md: number
      lg: number
      xl: number
    }
  }
}

const theme: ThemeType = {
  spacing: (vertical, horizontal) =>
    horizontal !== undefined ? `${vertical * 8}px ${horizontal ? horizontal * 8 : 0}px` : `${vertical * 8}px`,
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
    easing: {
      // This is the most common easing curve.
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
  },
  zIndex: {
    sideNav: "calc(var(--en-z-index-500) + 1)",
    appBar: "calc(var(--en-z-index-500) + 1)",
    pageHeader: "calc(var(--en-z-index-500))",
    upgradeBanner: "calc(var(--en-z-index-500) + 2)",
    modal: "calc(var(--en-z-index-500) + 3)",
    toastNotification: "calc(var(--en-z-index-500) + 4)",
    onBoardingButtonsContainer: "calc(var(--en-z-index-100) + 1)",
    popup: "calc(var(--en-z-index-300))",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: 5,
        },
        "*::-webkit-scrollbar-track": {
          "-webkit-box-shadow": "none",
          boxShadow: "none",
          borderRadius: 4,
          backgroundColor: "transparent",
        },
        "*::-webkit-scrollbar-thumb": {
          "-webkit-border-radius": 4,
          borderRadius: 4,
          backgroundColor: "#dedddf",
        },
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: TOP_NAV_HEIGHT,
      maxHeight: TOP_NAV_HEIGHT,
      height: TOP_NAV_HEIGHT,
    },
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h1: "h1",
        h2: "h2",
        body1: "p",
        body2: "p",
        subtitle1: "p",
        subtitle2: "p",
      },
    },
  },
  breakpoints: {
    values: {
      xs: 200,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 2400,
    },
    up: (key) => `@media (min-width:${theme.breakpoints.values?.[key as BreakPoints] || key}px)`,
    down: (key) => `@media (max-width:${((theme.breakpoints.values?.[key as BreakPoints] || key) as number) - 1}px)`,
    between: (start, end) =>
      `@media (min-width:${theme.breakpoints.values?.[start as BreakPoints] || start}px) and (max-width:${
        ((theme.breakpoints.values?.[end as BreakPoints] || end) as number) - 1
      }px)`,
  },
  color: {
    text: {
      primary: "#2b3952",
    },
    // Extreme Cloud One Theme Colors
    red: {
      1: "var(--en-color-base-red-1)" /* #250503 */,
      2: "var(--en-color-base-red-2)" /* #430a08 */,
      3: "var(--en-color-base-red-3)" /* #60120d */,
      4: "var(--en-color-base-red-4)" /* #7c1a15 */,
      5: "var(--en-color-base-red-5)" /* #97241e */,
      6: "var(--en-color-base-red-6)" /* #b13129 */,
      7: "var(--en-color-base-red-7)" /* #c73e36 */,
      8: "var(--en-color-base-red-8)" /* #db4e45 */,
      9: "var(--en-color-base-red-9)" /* #ed5f56 */,
      10: "var(--en-color-base-red-10)" /* #fa746c */,
      11: "var(--en-color-base-red-11)" /* #ff8c85 */,
      12: "var(--en-color-base-red-12)" /* #ffa59f */,
      13: "var(--en-color-base-red-13)" /* #ffbdb8 */,
      14: "var(--en-color-base-red-14)" /* #ffd3cf */,
      15: "var(--en-color-base-red-15)" /* #ffe9e7 */,
    },

    orange: {
      1: "var(--en-color-base-orange-1)" /* #230700 */,
      2: "var(--en-color-base-orange-2)" /* #410e00 */,
      3: "var(--en-color-base-orange-3)" /* #5d1700 */,
      4: "var(--en-color-base-orange-4)" /* #782100 */,
      5: "var(--en-color-base-orange-5)" /* #932c00 */,
      6: "var(--en-color-base-orange-6)" /* #ac3900 */,
      7: "var(--en-color-base-orange-7)" /* #c14706 */,
      8: "var(--en-color-base-orange-8)" /* #d55613 */,
      9: "var(--en-color-base-orange-9)" /* #e66824 */,
      10: "var(--en-color-base-orange-10)" /* #f57b39 */,
      11: "var(--en-color-base-orange-11)" /* #fc9253 */,
      12: "var(--en-color-base-orange-12)" /* #ffa973 */,
      13: "var(--en-color-base-orange-13)" /* #ffc096 */,
      14: "var(--en-color-base-orange-14)" /* #ffd5b7 */,
      15: "var(--en-color-base-orange-15)" /* #ffeadb */,
    },

    amber: {
      1: "var(--en-color-base-amber-1)" /* #1c0c00 */,
      2: "var(--en-color-base-amber-2)" /* #371700 */,
      3: "var(--en-color-base-amber-3)" /* #502400 */,
      4: "var(--en-color-base-amber-4)" /* #683000 */,
      5: "var(--en-color-base-amber-5)" /* #7f3e00 */,
      6: "var(--en-color-base-amber-6)" /* #954d00 */,
      7: "var(--en-color-base-amber-7)" /* #a85c00 */,
      8: "var(--en-color-base-amber-8)" /* #ba6b00 */,
      9: "var(--en-color-base-amber-9)" /* #cb7b00 */,
      10: "var(--en-color-base-amber-10)" /* #d98d0e */,
      11: "var(--en-color-base-amber-11)" /* #e69f29 */,
      12: "var(--en-color-base-amber-12)" /* #f0b249 */,
      13: "var(--en-color-base-amber-13)" /* #f9c56f */,
      14: "var(--en-color-base-amber-14)" /* #fcd89a */,
      15: "var(--en-color-base-amber-15)" /* #feecca */,
    },

    yellow: {
      1: "var(--en-color-base-yellow-1)" /* #140f00 */,
      2: "var(--en-color-base-yellow-2)" /* #2a1e00 */,
      3: "var(--en-color-base-yellow-3)" /* #3e2e00 */,
      4: "var(--en-color-base-yellow-4)" /* #513d00 */,
      5: "var(--en-color-base-yellow-5)" /* #634d00 */,
      6: "var(--en-color-base-yellow-6)" /* #765d00 */,
      7: "var(--en-color-base-yellow-7)" /* #876c00 */,
      8: "var(--en-color-base-yellow-8)" /* #977c00 */,
      9: "var(--en-color-base-yellow-9)" /* #a78c09 */,
      10: "var(--en-color-base-yellow-10)" /* #b59c1f */,
      11: "var(--en-color-base-yellow-11)" /* #c4ad38 */,
      12: "var(--en-color-base-yellow-12)" /* #d2bd57 */,
      13: "var(--en-color-base-yellow-13)" /* #dfce7a */,
      14: "var(--en-color-base-yellow-14)" /* #ebdea1 */,
      15: "var(--en-color-base-yellow-15)" /* #f5eecd */,
    },

    green: {
      1: "var(--en-color-base-green-1)" /* #021400 */,
      2: "var(--en-color-base-green-2)" /* #062600 */,
      3: "var(--en-color-base-green-3)" /* #0c3802 */,
      4: "var(--en-color-base-green-4)" /* #124a05 */,
      5: "var(--en-color-base-green-5)" /* #1b5c0b */,
      6: "var(--en-color-base-green-6)" /* #256e14 */,
      7: "var(--en-color-base-green-7)" /* #307f1f */,
      8: "var(--en-color-base-green-8)" /* #3f8f2c */,
      9: "var(--en-color-base-green-9)" /* #4e9f3c */,
      10: "var(--en-color-base-green-10)" /* #60af4e */,
      11: "var(--en-color-base-green-11)" /* #75bf63 */,
      12: "var(--en-color-base-green-12)" /* #8ccd7c */,
      13: "var(--en-color-base-green-13)" /* #a4db98 */,
      14: "var(--en-color-base-green-14)" /* #c0e8b7 */,
      15: "var(--en-color-base-green-15)" /* #def4d9 */,
    },

    forest: {
      1: "var(--en-color-base-forest-1)" /* #001405 */,
      2: "var(--en-color-base-forest-2)" /* #00260c */,
      3: "var(--en-color-base-forest-3)" /* #003913 */,
      4: "var(--en-color-base-forest-4)" /* #014b1c */,
      5: "var(--en-color-base-forest-5)" /* #075d26 */,
      6: "var(--en-color-base-forest-6)" /* #0f6f31 */,
      7: "var(--en-color-base-forest-7)" /* #19803d */,
      8: "var(--en-color-base-forest-8)" /* #25904b*/,
      9: "var(--en-color-base-forest-9)" /* #35a05b */,
      10: "var(--en-color-base-forest-10)" /* #47b16d */,
      11: "var(--en-color-base-forest-11)" /* #5dc180 */,
      12: "var(--en-color-base-forest-12)" /* #76cf95 */,
      13: "var(--en-color-base-forest-13)" /* #93ddad */,
      14: "var(--en-color-base-forest-14)" /* #b3eac6 */,
      15: "var(--en-color-base-forest-15)" /* #d7f5e2 */,
    },

    cyan: {
      1: "var(--en-color-base-cyan-1)" /* #001315 */,
      2: "var(--en-color-base-cyan-2)" /* #002429 */,
      3: "var(--en-color-base-cyan-3)" /* #00363d */,
      4: "var(--en-color-base-cyan-4)" /* #004750 */,
      5: "var(--en-color-base-cyan-5)" /* #005963 */,
      6: "var(--en-color-base-cyan-6)" /* #026a76 */,
      7: "var(--en-color-base-cyan-7)" /* #0b7b88 */,
      8: "var(--en-color-base-cyan-8)" /* #178b98 */,
      9: "var(--en-color-base-cyan-9)" /* #269ba9 */,
      10: "var(--en-color-base-cyan-10)" /* #3aacb9 */,
      11: "var(--en-color-base-cyan-11)" /* #50bcc8 */,
      12: "var(--en-color-base-cyan-12)" /* #6bcbd6 */,
      13: "var(--en-color-base-cyan-13)" /* #8adae2 */,
      14: "var(--en-color-base-cyan-14)" /* #ade7ee */,
      15: "var(--en-color-base-cyan-15)" /* #d4f4f7 */,
    },

    blue: {
      1: "var(--en-color-base-blue-1)" /* #001315 */,
      2: "var(--en-color-base-blue-2)" /* #002429 */,
      3: "var(--en-color-base-blue-3)" /* #00363d */,
      4: "var(--en-color-base-blue-4)" /* #004750 */,
      5: "var(--en-color-base-blue-5)" /* #005963 */,
      6: "var(--en-color-base-blue-6)" /* #026a76 */,
      7: "var(--en-color-base-blue-7)" /* #0b7b88 */,
      8: "var(--en-color-base-blue-8)" /* #178b98 */,
      9: "var(--en-color-base-blue-9)" /* #269ba9 */,
      10: "var(--en-color-base-blue-10)" /* #3aacb9 */,
      11: "var(--en-color-base-blue-11)" /* #50bcc8 */,
      12: "var(--en-color-base-blue-12)" /* #6bcbd6 */,
      13: "var(--en-color-base-blue-13)" /* #8adae2 */,
      14: "var(--en-color-base-blue-14)" /* #ade7ee */,
      15: "var(--en-color-base-blue-15)" /* #d4f4f7 */,
    },

    purple: {
      1: "var(--en-color-base-purple-1)" /* #0f0d24 */,
      2: "var(--en-color-base-purple-2)" /* #1e1a46 */,
      3: "var(--en-color-base-purple-3)" /* #2d2765 */,
      4: "var(--en-color-base-purple-4)" /* #3c3582 */,
      5: "var(--en-color-base-purple-5)" /* #4b449b */,
      6: "var(--en-color-base-purple-6)" /* #5b53b2 */,
      7: "var(--en-color-base-purple-7)" /* #6a62c6 */,
      8: "var(--en-color-base-purple-8)" /* #7a72d7 */,
      9: "var(--en-color-base-purple-9)" /* #8981e5 */,
      10: "var(--en-color-base-purple-10)" /* #9a92f2 */,
      11: "var(--en-color-base-purple-11)" /* #aba3fb */,
      12: "var(--en-color-base-purple-12)" /* #bbb5ff */,
      13: "var(--en-color-base-purple-13)" /* #ccc7ff */,
      14: "var(--en-color-base-purple-14)" /* #ddd9ff */,
      15: "var(--en-color-base-purple-15)" /* #eeecff */,
    },

    magenta: {
      1: "var(--en-color-base-magenta-1)" /* #190a1f */,
      2: "var(--en-color-base-magenta-2)" /* #31143b */,
      3: "var(--en-color-base-magenta-3)" /* #471f56 */,
      4: "var(--en-color-base-magenta-4)" /* #5c2a6e */,
      5: "var(--en-color-base-magenta-5)" /* #703884 */,
      6: "var(--en-color-base-magenta-6)" /* #844699 */,
      7: "var(--en-color-base-magenta-7)" /* #9555ad */,
      8: "var(--en-color-base-magenta-8)" /* #a565bd */,
      9: "var(--en-color-base-magenta-9)" /* #b575cc */,
      10: "var(--en-color-base-magenta-10)" /* #c486d9 */,
      11: "var(--en-color-base-magenta-11)" /* #d099e4 */,
      12: "var(--en-color-base-magenta-12)" /* #dcaced */,
      13: "var(--en-color-base-magenta-13)" /* #e6c0f4 */,
      14: "var(--en-color-base-magenta-14)" /* #f0d4f9 */,
      15: "var(--en-color-base-magenta-15)" /* #f8e9fd */,
    },

    neutralDark: {
      1: "var(--en-color-neutral-dark-1)" /* #0f0f15 */,
      2: "var(--en-color-neutral-dark-2)" /* #1e1f2a */,
      3: "var(--en-color-neutral-dark-3)" /* #2d2f3e */,
      4: "var(--en-color-neutral-dark-4)" /* #3d3f52 */,
      5: "var(--en-color-neutral-dark-5)" /* #4d4f63 */,
      6: "var(--en-color-neutral-dark-6)" /* #5b5e75 */,
      7: "var(--en-color-neutral-dark-7)" /* #6b6e86 */,
      8: "var(--en-color-neutral-dark-8)" /* #7a7d96 */,
      9: "var(--en-color-neutral-dark-9)" /* #898ca4 */,
      10: "var(--en-color-neutral-dark-10)" /* #999cb3 */,
      11: "var(--en-color-neutral-dark-11)" /* #a9acc1 */,
      12: "var(--en-color-neutral-dark-12)" /* #babcce */,
      13: "var(--en-color-neutral-dark-13)" /* #caccdb */,
      14: "var(--en-color-neutral-dark-14)" /* #dbdce7 */,
      15: "var(--en-color-neutral-dark-15)" /* #ecedf2 */,
      16: "var(--en-color-neutral-dark-16)" /* #f8f8fb */,
    },

    neutralLight: {
      1: "var(--en-color-neutral-light-1)" /* #0f1011 */,
      2: "var(--en-color-neutral-light-2)" /* #1e2022 */,
      3: "var(--en-color-neutral-light-3)" /* #2e3033 */,
      4: "var(--en-color-neutral-light-4)" /* #3d4044 */,
      5: "var(--en-color-neutral-light-5)" /* #4d5055 */,
      6: "var(--en-color-neutral-light-6)" /* #5c6065 */,
      7: "var(--en-color-neutral-light-7)" /* #6c7075 */,
      8: "var(--en-color-neutral-light-8)" /* #7b7f84 */,
      9: "var(--en-color-neutral-light-9)" /* #8a8e93 */,
      10: "var(--en-color-neutral-light-10)" /* #9a9ea3 */,
      11: "var(--en-color-neutral-light-11)" /* #aaaeb2 */,
      12: "var(--en-color-neutral-light-12)" /* #babdc1 */,
      13: "var(--en-color-neutral-light-13)" /* #cacdd0 */,
      14: "var(--en-color-neutral-light-14)" /* #dcdde0 */,
      15: "var(--en-color-neutral-light-15)" /* #edeeef */,
      16: "var(--en-color-neutral-light-16)" /* #ffffff */,
    },

    background: {
      surfaceElevation0: "var(--en-theme-color-background-surface-elevation-0)" /* #1E1F2A */,
      surfaceElevation1: "var(--en-theme-color-background-surface-elevation-1)" /* #2D2F3E */,
      surfaceElevation2: "var(--en-theme-color-background-surface-elevation-2)" /* #3D3F52 */,
      surfaceElevation3: "var(--en-theme-color-background-surface-elevation-3)" /* #4D4F63 */,
      accentDefault: "var(--en-theme-color-background-accent-default)" /* #8981E5 */,
      accentEmphasis: "var(--en-theme-color-background-accent-emphasis)" /* #ABA3FB */,
      successDefault: "var(--en-theme-color-background-success-default)" /* #75BF63 */,
      warningDefault: "var(--en-theme-color-background-warning-default)" /* #E69F29 */,
      dangerDefault: "var(--en-theme-color-background-danger-default)" /* #FA746C */,
      dangerEmphasis: "var(--en-theme-color-background-danger-emphasis)" /* #FFA59F */,
      infoDefault: "var(--en-theme-color-background-info-default)" /* #79B0F5 */,
      inverseDefault: "var(--en-theme-color-background-inverse-default)" /* #f8f8fb */,
      inverseEmphasis: "var(--en-theme-color-background-inverse-emphasis)" /* #CACCDB */,
      transparentDefault: "var(--en-theme-color-background-transparent-default)" /* rgba(0,0,0,0) | #000000 */,
      transparentEmphasis: "var(--en-theme-color-background-transparent-emphasis)" /* rgba(45, 47, 62, 0.60) */,
      paper: "#FAFBFC",
      failureDefault: "#423B48",
      greyDefault: "#4A4C5F",
    },

    content: {
      default: "var(--en-theme-color-content-default)" /*#F8F8FB */,
      secondary: "var(--en-theme-color-content-secondary)" /*#BABCCE */,
      tertiary: "var(--en-theme-color-content-tertiary)" /*#898CA4 */,
      inverseDefault: "var(--en-theme-color-content-inverse-default)" /*#1E1F2A */,
      accentDefault: "var(--en-theme-color-content-accent-default)" /*#8981E5 */,
      accentEmphasis: "var(--en-theme-color-content-accent-emphasis)" /*#ABA3FB */,
      successDefault: "var(--en-theme-color-content-success-default)" /*#75BF63 */,
      successOnbg: "var(--en-theme-color-content-success-onbackground)" /*#062600 */,
      warningDefault: "var(--en-theme-color-content-warning-default)" /*#E69F29 */,
      warningOnbg: "var(--en-theme-color-content-warning-onbackground)" /*#371700 */,
      dangerDefault: "var(--en-theme-color-content-danger-default)" /*#FA746C */,
      dangerOnbg: "var(--en-theme-color-content-danger-onbackground)" /*#430A08 */,
      infoDefault: "var(--en-theme-color-content-info-default)" /*#79B0F5 */,
      infoOnbg: "var(--en-theme-color-content-info-onbackground)" /*#061F44 */,
    },

    border: {
      divider: "var(--en-theme-color-border-divider)" /*#4D4F63 */,
      default: "var(--en-theme-color-border-default)" /*#999CB3 */,
      emphasis: "var(--en-theme-color-border-emphasis)" /*#ECEDF2 */,
      accentDefault: "var(--en-theme-color-border-accent-default)" /*#8981E5 */,
      accentEmphasis: "var(--en-theme-color-border-accent-emphasis)" /*#ABA3FB */,
      dangerDefault: "var(--en-theme-color-border-danger-default)" /*#FA746C */,
    },

    // Old Theme Colors
    tertiary: {
      700: "#edeff3",
      600: "#a3b2cc",
      500: "#00a5bf",
      400: "#E5F6F9",
      300: "#F1F1F1",
    },
    grey: {
      A100: "#24202B",
      A200: "#75869E",
      A300: "#E4E8F2",
      900: "#5A575F",
      800: "#8E8C92",
      700: "#C4C3C6",
      600: "#EFEFEF",
      500: "#36455f",
      400: "#ebeef5",
      300: "#516382",
    },
    secondary: {
      main: "#C3025F",
      900: "#D14185",
      800: "#DE7DAC",
      700: "#ECBCD3",
      600: "#B9BEC7",
      500: "#E0F6F9",
      400: "#DBDFE6",
      300: "#f9f9f9",
    },
    primary: {
      main: "#162133",
      900: "#7140AF",
      800: "#9E7CC9",
      700: "#CCBBE1",
      600: "#007e91",
      500: "#00B0CC",
      400: "#A67AEB",
    },
    success: {
      main: "#66BC53",
      900: "#8BCB7D",
      800: "#AFDAA6",
      700: "#D6EAD0",
      600: "#F1FAEF",
      500: "#008700",
      400: "#E5F3E5",
    },
    warning: {
      main: "#E2B100",
      900: "#E9C33F",
      800: "#EDD57C",
      700: "#F4E7BB",
      600: "#F9F5DF",
      500: "#B87A00",
    },
    info: {
      main: "#2A96F3",
      900: "#70B5FA",
      800: "#9FCCFA",
      700: "#CDE3FA",
      600: "#E7F0F9",
    },
    error: {
      main: "#EB0000",
      light: "#EB7A7A",
      dark: "#f44336",
      900: "#F67B70",
      800: "#F8A9A2",
      700: "#FBD5D2",
      600: "#F8E9E7",
    },
  },
  typography: {
    fontFamily: ["Noto Sans", "open sans", "sans-serif"].join(","),
    h1: {
      fontSize: 25,
      color: "#2b3952",
      fontFamily: "Noto Sans",
    },
    h2: {
      fontSize: 22,
      color: "#2b3952",
      fontFamily: "Noto Sans",
    },
    h3: {
      fontSize: 19,
      color: "#2b3952",
      fontFamily: "Noto Sans",
    },
    h4: {
      fontSize: 16,
      color: "#2b3952",
      fontFamily: "Noto Sans",
    },
    subtitle1: {
      fontSize: 13,
      color: "#2b3952",
      fontFamily: "Noto Sans",
    },
    subtitle2: {
      fontSize: 14,
      color: "#2b3952",
      fontFamily: "Noto Sans",
    },
    body1: {
      fontSize: 16,
      color: "#2b3952",
      fontFamily: "Noto Sans",
    },
    body2: {
      fontSize: 16,
      color: "#2b3952",
      fontFamily: "Noto Sans",
    },
  },
  shape: {
    borderRadius: {
      xs: "var(--en-theme-border-radius-xs)" as unknown as number,
      sm: "var(--en-theme-border-radius)" as unknown as number,
      md: "var(--en-theme-border-radius-md)" as unknown as number,
      lg: "var(--en-theme-border-radius-lg)" as unknown as number,
      xl: "var(--en-theme-border-radius-xl)" as unknown as number,
    },
  },
}

// const theme = createTheme(themeData as ThemeOptions)
export default theme
